import DefaultOptions from './AppOptions'
import CdekOptions from './cdek/AppOptions'
import RwOptions from './rw/AppOptions'
import Callplex from './callplex/AppOptions'
import ZtMainOptions from './ztmain/AppOptions'

export default [
  DefaultOptions,
  CdekOptions,
  Callplex,
  RwOptions,
  ZtMainOptions,
]
