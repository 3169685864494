import { Subject } from '../subject'

export class EventBus {
  events

  constructor () {
    this.events = {}
  }

  update (eventName, update) {
    const event = this.getEvent(eventName)
    const data = {
      ...(event.value || {}),
      ...update
    }

    return this.publish(eventName, data)
  }

  getEvent (event) {
    if (!this.events[event]) {
      this.events[event] = new Subject()
    }

    return this.events[event]
  }

  getEventValue (event) {
    return this.getEvent(event).value
  }

  publish (event, value) {
    const subject = this.getEvent(event)

    return subject.publish(value)
  }

  subscribe (event, callback) {
    const subject = this.getEvent(event)

    return subject.subscribe(callback)
  }
}
