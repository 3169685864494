// import '@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js';

import { Runtime } from './runtime'

export * from './app'
export * from './runtime'
export * from './subject'
export * from './event-bus'

export const runtime = new Runtime()
