export default {
  id: 'callplex',
  debug: false,
  app: 'orderadmin',
  title: 'CallPlex',
  domain: 'callplex',
  localServer: 'callplexapp.orderadmin.ru',
  defaultServer: 'https://panel.orderadmin.ru',
  securityLogo: 'assets/cp-logo.png',
  logoSrc: 'assets/cp-logo-light.png',
  logoLeft: 'assets/cp-logo.png',
  logoTop: 'assets/cp-logo.png',
  theme: 'default',
  servers: [],
  locale: {
    name: 'Русский',
    locale: 'ru',
    flag: 'ru'
  }
}
