class PermissionsService {
  static get roles () {
    return (JSON.parse(localStorage.getItem('userData')) || { roles: [] }).roles.map(({ id }) => id)
  }

  static get permissions () {
    return JSON.parse(localStorage.getItem('permissions'))
  }

  get () {
    const query = {
      page: 1,
      per_page: 250,
      filter: [
        { type: 'eq', field: 'state', value: 'active' },
        { type: 'eq', field: 'type', value: 'object' }
      ]
    }
    return import('./../config/permissions').then(m => m.getPermissions(PermissionsService.roles) || [])
      .then(permissions => {
        const objectPermissions = permissions.reduce((acc, permission) => {
          if (permission.type === 'object' && permission.permission.includes('R')) {
            acc[permission.object] = permission
          }

          return acc
        }, {})

        localStorage.setItem('permissions', JSON.stringify(objectPermissions))
        return objectPermissions
      })
  }
}

export const permissions = new PermissionsService()
