import qs from 'qs'
import { EventBus, createApp, runtime } from './packages'
import AppOptions from './config/AppOptions'
import { initWorkspace } from './workspace'
import { UserService } from './services/user.service'
import Cookies from 'quasar/src/plugins/Cookies.js';
import entities from './config/Entities'
import instructions from './config/Instructions'
import { permissions } from './services/permissions.service'

initWorkspace()

// Import titlebar plugin only under electron runtime
// if (navigator && navigator.userAgent && navigator.userAgent.includes('Electron')) {
//   import('custom-electron-titlebar')
//     .then(m => {
//       // eslint-disable-next-line no-new
//       new m.Titlebar({
//         backgroundColor: m.Color.fromHex('#2d353c'),
//         icon: 'favicon.png'
//       })
//     })
// }

const eventBus = new EventBus()

createApp({
  name: 'oa-loading',
  eventBus,
  root: document.getElementById('loader'),
  bootstrap: (root, eventBus, options) => {
    return import('./apps/loading/main')
      .then(module => {
        return module.bootstrap(root, eventBus, options)
      })
  },
  options: (rules) => {
    return rules
  },
  rules: (rules) => {
    return rules.app === 'loading'
  }
})

const LoadingApp = runtime.getApp('oa-loading')
const loadingApp = new LoadingApp()

function startLoading () {
  loadingApp.mount({ app: 'loading' })
}

function stopLoading () {
  loadingApp.unmount()
}

createApp({
  name: 'oa-app',
  eventBus,
  root: document.getElementById('app'),
  bootstrap: (root, eventBus, options) => {
    startLoading()

    return permissions.get()
      .then(permissions => {
        return import('./apps/app/main')
          .then(module => {    
            return module.bootstrap(root, eventBus, { ...options, permissions })
          })
      })
      .finally(() => {
        stopLoading()
      })
  },
  options: (rules) => {
    return rules
  },
  rules: (rules) => {
    return rules.app === 'app'
  }
})

createApp({
  name: 'oa-shopify',
  eventBus,
  root: document.getElementById('app'),
  bootstrap: (root, eventBus, options) => {
    startLoading()
    return import('./apps/shopify/main')
      .then(module => {
        return module.bootstrap(root, eventBus, options)
      })
      .finally(() => {
        stopLoading()
      })
  },
  options: (rules) => {
    return rules
  },
  rules: (rules) => {
    return rules.app === 'shopify'
  }
})

createApp({
  name: 'oa-terminal',
  eventBus,
  root: document.getElementById('app'),
  bootstrap: (root, eventBus, options) => {
    startLoading()
    return import('./apps/terminal/main')
      .then(module => {
        return module.bootstrap(root, eventBus, options)
      })
      .finally(() => {
        stopLoading()
      })
  },
  options: (rules) => {
    return rules
  },
  rules: (rules) => {
    return rules.app === 'terminal'
  }
})

createApp({
  name: 'oa-security',
  eventBus,
  root: document.getElementById('app'),
  bootstrap: (root, eventBus, options) => {
    startLoading()
    return import('./apps/security-hub/main')
      .then(module => {
        return module.bootstrap(root, eventBus, options)
      })
      .finally(() => {
        stopLoading()
      })
  },
  options: (rules) => {
    return rules
  },
  rules: (rules) => {
    return rules.app === 'security'
  }
})

const query = qs.parse(window.location.href.slice(window.location.href.indexOf('?') + 1))

function getApp (user) {
  if (window.location.href.includes('/app-shopify')) {
    return 'shopify'
  }

  if (!user) {
    return 'security'
  }

  const accessToTerminal = [3, 7, 29]

  if (window.location.href.includes('terminal') && user.roles.find(({ id }) => accessToTerminal.includes(id))) {
    return 'terminal'
  }

  // Force user to terminal
  if (user.roles.find(({ id }) => id === 7) && !window.location.href.slice(window.location.href.indexOf('#/') + 2, window.location.href.indexOf('?')) && window.innerWidth < 700) {
    return 'terminal'
  }

  return 'app'
}

if (query.refreshToken) {
  startLoading()
  localStorage.clear()
  initWorkspace(query.domain)
  const cookies = Cookies

  UserService.refreshAuth(query.refreshToken, window.appOptions.defaultServer)
    .then(user => {
      user.expiresTimestamp = user.expires_in
      localStorage.setItem('user', JSON.stringify(user))

      cookies.set('server', window.appOptions.defaultServer)
      cookies.set('locale', window.appOptions.locale.locale)
      cookies.set('user', user, user.expires_in)

      return UserService.getById(0)
    })
    .then(userdata => {
      stopLoading()
      localStorage.setItem('userData', JSON.stringify(userdata))
      eventBus.update('rules', {
        user: userdata,
        app: getApp(userdata),
        entities,
        defaultAppOptions: AppOptions,
        appOptions: window.appOptions
      })
    })
    .catch((error) => {
      stopLoading()
      eventBus.publish('rules', {
        entities,
        error,
        user: JSON.parse(localStorage.getItem('userData')),
        defaultAppOptions: AppOptions,
        appOptions: window.appOptions,
        app: 'security',
        instructions
      })
    })
} else {
  const user = JSON.parse(localStorage.getItem('userData'))

  eventBus.publish('rules', {
    entities,
    user,
    app: getApp(user),
    defaultAppOptions: AppOptions,
    appOptions: window.appOptions,
    instructions
  })
}
